<template>
    <div class="p-4 mx-2 my-4 bg-white w-36 h-20 shadow-md rounded-xl group hover:text-orange hover:bg-orange-tint transition duration-300 ease cursor-pointer" :class="{'shadow-none': selected, 'text-orange': selected, 'bg-orange-tint': selected}">
        <p class="text-sm font-bold truncate">{{address.title}}</p>
        <p class="text-xs text-gray-500 truncate font-extralight group-hover:text-orange" :class="{'text-orange': selected}">{{address.stAddress}} <br>
            {{address.city}}, {{address.country}}</p>
    </div>
</template>

<script>
    export default {
        props: {
            selected: Boolean,
            address: Object
        }
    }
</script>