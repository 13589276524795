<template>
  <page-layout linkDefault="/" title="Select Location" :backButton="true">
    <div
      class="lg:w-full lg:mx-auto h-screen p-4 pb-24 bottom-0 left-0 right-0"
    >
      <div>
        <div
          class="
            w-full
            h-16
            my-4
            bg-white
            rounded-full
            shadow-lg
            flex
            item-center
            justify-center
            lg:w-96
          "
        >
          <p
            class="
              h-full
              flex
              items-center
              text-lg
              rounded
              px-8
              font-bold
              mx-2
              cursor-pointer
              hover:text-orange hover:bg-orange-tint
              transition
              duration-300
              ease
              capitalize
            "
            @click="
              this.type = 'online';
              this.selected = '';
            "
            :class="{
              'text-orange bg-orange-tint': type == 'online',
            }"
          >
            online
          </p>
          <p
            v-if="tutor_previewed.in_person"
            class="
              h-full
              flex
              items-center
              rounded
              px-8
              font-bold
              text-lg
              mx-2
              cursor-pointer
              hover:text-orange hover:bg-orange-tint
              transition
              duration-300
              ease
              capitalize
            "
            @click="
              this.type = 'in-person';
              this.selected = '';
            "
            :class="{
              'text-orange bg-orange-tint': type == 'in-person',
            }"
          >
            in person
          </p>
        </div>

        <transition-group
          class="flex justify-center flex-wrap lg:w-96 relative"
          tag="ul"
          appear
          name="scale-up"
          v-if="type == 'in-person'"
        >
          <li
            v-for="(address, index) in user.university.locations"
            :key="index"
          >
            <Address
              :address="address"
              @click="this.selected = address.title"
              :selected="selected == address.title"
            />
          </li>
        </transition-group>
        <div
          class="flex justify-center pb-24 flex-wrap w-full lg:w-96"
          v-if="type == 'online'"
        >
          <div class="w-80 lg:w-96 mx-auto bg-beige rounded-xl p-8 text-center">
            <p class="font-bold text my-4">
              Choose your preferred digital meetup app
            </p>
            <transition-group
              name="scale-up"
              appear
              tag="ul"
              class="flex justify-center lg:w-96 relative"
            >
              <li
                key="gmeet"
                class="
                  w-48
                  rounded-lg
                  flex flex-wrap
                  p-4
                  mx-2
                  justify-center
                  transition
                  duration-300
                  ease
                  cursor-pointer
                  items-center
                  shadow-lg
                  mx-auto
                  hover:bg-orange-tint
                  group
                "
                :class="{
                  'bg-orange-tint': selected == 'gmeet',
                  'shadow-none': selected == 'gmeet',
                }"
                @click="this.selected = 'gmeet'"
              >
                <svg class="w-24" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 87.5 72">
                  <path fill="#00832d" d="M49.5 36l8.53 9.75 11.47 7.33 2-17.02-2-16.64-11.69 6.44z"/>
                  <path fill="#0066da" d="M0 51.5V66c0 3.315 2.685 6 6 6h14.5l3-10.96-3-9.54-9.95-3z"/>
                  <path fill="#e94235" d="M20.5 0L0 20.5l10.55 3 9.95-3 2.95-9.41z"/>
                  <path fill="#2684fc" d="M20.5 20.5H0v31h20.5z"/>
                  <path fill="#00ac47" d="M82.6 8.68L69.5 19.42v33.66l13.16 10.79c1.97 1.54 4.85.135 4.85-2.37V11c0-2.535-2.945-3.925-4.91-2.32zM49.5 36v15.5h-29V72h43c3.315 0 6-2.685 6-6V53.08z"/>
                  <path fill="#ffba00" d="M63.5 0h-43v20.5h29V36l20-16.57V6c0-3.315-2.685-6-6-6z"/>
                </svg> 
                <p
                  class="
                    font-bold
                    group-hover:text-orange
                    transition
                    duration-300
                    ease
                  "
                  :class="{ 'text-orange': selected == 'gmeet' }"
                >
                  Google Meet
                </p>
              </li>
              <!-- <div
                class="w-48 rounded-lg flex flex-wrap p-4 mx-2 justify-center items-center shadow-lg" :class="{'bg-orange-tint': selected == 'hangouts', 'shadow-none': selected == 'hangouts'}" @click="this.selected = 'hangouts'"
              >
                <svg
                class="fill-current text-gray-300 w-24" :class="{'text-orange': selected == 'hangouts'}"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 50 50"
                >
                  <path
                    d="M38.957,8.196C35.216,4.259,29.946,2,24.5,2C13.748,2,5,10.748,5,21.5c0,10.585,8.479,19.228,19,19.494v6.801l1.304-0.416	c0.177-0.057,17.671-5.867,18.695-25.828C44.251,16.627,42.461,11.885,38.957,8.196z M31.125,28.873v-4.998h-4v-6.75h7.75V25	C34.875,27.095,33.204,28.807,31.125,28.873z M14.125,23.875v-6.75h7.75V25c0,2.095-1.671,3.807-3.75,3.873v-4.998H14.125z"
                  ></path>
                </svg>
                <p class="font-bold" :class="{'text-orange': selected == 'hangouts'}">Hangouts</p>
              </div> -->
            </transition-group>
          </div>
        </div>
        <div class="fixed bottom-24 right-5">
          <Button
            v-if="selected != ''"
            type="primary"
            text="book!"
            @click="takeClass()"
            :disabled="booking"
            floating
          ></Button>
        </div>
      </div>
    </div>
  </page-layout>
</template>

<script>
import PageLayout from "../components/base/pageLayout.vue";
import Address from "../components/Address.vue";
import Button from "../components/button.vue";
import { useRouter } from "vue-router";
import { mapState, mapMutations, mapActions } from "vuex";
import mixpanel from "mixpanel-browser";
import { me3temed } from "../presets";

export default {
  setup() {
    const router = useRouter();
    return { router };
  },
  created() {
    this.loadSessionInfo();

    mixpanel.track("visited book location", {
      tutor: this.tutor_previewed.user.username,
      ...this.session_previewed,
    });
  },
  computed: {
    ...mapState([
      "user",
      "session_previewed",
      "tutor_previewed",
      "session_confirmation_preview",
    ]),
  },
  data() {
    return {
      type: "online",
      selected: "",
      booking: false,
      me3temed_user: null,
    };
  },
  components: {
    PageLayout,
    Address,
    Button,
  },
  methods: {
    ...mapMutations(["SetSessionPreviewLocation", "loadSessionInfo"]),
    ...mapActions(["send_session_request"]),
    async takeClass() {
      if (!this.booking) {
        this.booking = true;
        this.SetSessionPreviewLocation(this.selected);
        await this.send_session_request({
          course_id: this.session_previewed.class._id,
          location: this.session_previewed.location,
          slot: this.session_previewed.time,
          info: this.session_previewed.info,
          topic: this.session_previewed.topic,
        });
        this.$router.replace(
          `/sessions/${this.session_previewed._id}`
        );
      }
    },
  },
  name: "PickLocation",
};
</script>
